import { BaseModel } from './base.model';

export class User extends BaseModel{

    private _token:Token;
    public roles: any;
    public name: string;
    public photo: string;
    public created_at: string;
    public picture:any;
    public email:any;
    public nuit:any;
    public phone:any;
    public address:any;
    public club:any;

    set token(token:Token){
        this._token=token;
    }

    get token(){
        return this._token;
    }

    isLoggedIn():boolean{
        return  this.isTokenExpired();
    }

    private isTokenExpired():boolean{
        return !!this._token && new Date() < new Date(this.token.expires_in);
    }

    static fromJsonToObject(jsonData:any):User{
        return Object.assign(new User(),jsonData);
    }

}

export class Token{
    constructor(
        public token_type:string,
        public expires_in:Date,
        public access_token:string,
        public refresh_token:string
    ){}
}