import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ng-home',
    templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit {
    currentYear:Date=new Date();
    
    constructor() { }

    ngOnInit() { }
}