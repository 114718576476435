<div class="login-box">

    <div class="login-logo">
      <a routerLink="/login"> <img style="width:100px;height:100px;" src="/assets/Khuzela.png" alt="Khuzela"></a>
    </div>
    <!-- /.login-logo -->
    <p class="login-box-msg">Faça login para iniciar a sessão</p>
    <div class="box-body">
  
      <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm.value)">

        <app-input errorMessage="Email Inválido" >
          <input formControlName="email" type="email" class="form-control" placeholder="Email">
          <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
        </app-input>

        <app-input errorMessage="A senha deve ter 8 caracteres." >
          <input formControlName="password" type="password" class="form-control" placeholder="Password">
          <span class="glyphicon glyphicon-lock form-control-feedback"></span>
        </app-input>


        <div class="row">

          <div class="col-xs-12" style="margin-top: 5px;">
            <ng-button 
              [button]="{
                name:'Entrar',
                color:'danger btn-block',
                faIconClass:'sign-in',
                type:'submit',
                loadingText:'AUTENTICANDO',
                isLoading:isButtonLoading,
                isDisabled:loginForm.invalid || isButtonLoading
              }">
            </ng-button>

          </div>

          <div class="col-xs-12">
            <div class="checkbox icheck text-center">
              <p class="text-primary">
                Esqueceu a sua senha?
                <i data-toggle="tooltip" title="Contacte o Administrador do Sistema (849231169)" class="fa fa-info-circle"></i>
              </p>
            </div>
          </div>

          <!-- /.col -->
        
          <!-- /.col -->
        </div>
      </form>

  
    </div>
    <!-- /.login-box-body -->
  </div>
  <!-- /.login-box -->