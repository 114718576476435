export const environment = {
  production: true,
  // imali_api:'http://khuzeladmin.paytek-africa.com/api',
  // imali_api_auth:'http://khuzeladmin.paytek-africa.com/oauth/token'
  // imali_api:'https://apiproduction.khuzela.com/api',
  // imali_api_auth:'https://apiproduction.khuzela.com/oauth/token',
  // imali_api:'https://testapi.khuzela.com/api',
  // imali_api_auth:'https://testapi.khuzela.com/oauth/token'
  imali_api:'https://paytek-africa.com/paytekchalenge/public/api',
  imali_api_auth:'https://paytek-africa.com/paytekchalenge/public/oauth/token'
}


