<button [disabled]="button.isDisabled || button.isLoading" [type]="button?.type" class="btn btn-outline-{{button?.color}} button-margin">

  <span *ngIf="!button.isLoading">
    <i class="{{button?.faIconClass}}"></i>
    {{ button?.name?.toUpperCase() }}
  </span>

  <span *ngIf="button.isLoading">
    <i class="fa fa-circle-o-notch fa-spin"></i>
    {{ button?.loadingText?.toUpperCase() }}
  </span>

</button>
