import { Component, Input } from '@angular/core';

@Component({
  selector: 'ng-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent{
  @Input() button:IButton;
}

interface IButton{
  type?:string;
  color?:string;
  name?:string;
  loadingText?:string;
  faIconClass?:string;
  isLoading?:boolean;
  isDisabled?:boolean;
}
