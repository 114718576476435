import { Token, User } from '../models/user.model';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppApiInterface } from 'src/app/app.api';

interface IToken{
    token_type:string;
    expires_in:string;
    access_token:string;
    refresh_token:string;
}

@Injectable()
export class AuthService {


    constructor(
        private http: HttpClient,
        private toastr: ToastrManager,
        private router: Router,
    ){}


    postData(cridentials: {email: string,password: string}){
        return {
            grant_type: 'password',
            client_id: 4,
            client_secret: '8NLKsY2Q8sADCx7hb2zHgRmYQQ23t5Yiu3SpOwHV',
            username: cridentials.email,
            password: cridentials.password,
            scope: ''
        }
    }

    login(cridentials: {email: string,password: string}): Observable<boolean> {

       return this.http.post<IToken>(`${AppApiInterface.IMALI_API_AUTH}`, this.postData(cridentials))
        .pipe(switchMap(authData=>{

                return this.loadUserFromApi(authData).pipe(map((user) => {

                    user.token=new Token(
                        authData.token_type,
                        new Date(new Date().getTime() + parseInt(authData.expires_in) * 1000),
                        authData.access_token,
                        authData.refresh_token
                    );

                    localStorage.setItem('user',JSON.stringify(user))

                    return true;
                }))

        }))

    }

    loadUserFromApi(token:IToken):Observable<User> {
        return this.http.get(`${AppApiInterface.IMALI_API}/user-api`,{headers:{'Authorization':`Bearer ${token.access_token}`}})
        .pipe(map((userData=>User.fromJsonToObject(userData))))
    }

    getLogs():Observable<any> {
        return this.http.get(`${AppApiInterface.IMALI_API}/get-app-logs`)
        .pipe(map((userData=>userData)))
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/login'])
        this.toastr.infoToastr('Utilizador Desconectado!', 'Informação',{position:'top-center'})
    }

    get user(): User {
        return User.fromJsonToObject(JSON.parse(localStorage.getItem('user')))
    }

}
