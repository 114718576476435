<ngx-loading-bar [color]="'#f35309'" [includeSpinner]="false" [height]="'2px'"></ngx-loading-bar>

<div class="wrapper">

  <!-- header -->
  <ng-header></ng-header>
  <!-- end header -->

  
  <div class="content-wrapper">

      <!-- mensagens -->
      <ng-messages></ng-messages>
      <!-- mensagens -->
   <div class="content">


        <!-- main content -->
        <router-outlet></router-outlet>
        <!-- main content -->

    </div>

  </div>

  <!-- footer -->
  <footer class="main-footer">


    <div class="pull-right hidden-xs">
      <b>Versão</b> 1.2
    </div>
  
    <strong>
      Copyright &copy; {{currentYear | date:'yyyy'}} <a routerLink="http://khuzela.com">Khuzela</a>.
    </strong>
    Todos Direitos Reservados.
  
  </footer>
  <!-- end footer -->
  
  </div>
