import { RoleGuard } from './shared/guards/role.guard';
import { ForbiddenComponent } from './core/components/forbidden/forbidden.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// components
import { LoginComponent } from './core/components/login/login.component';
import { HomeComponent } from './core/components/home/home.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';

// services
import { AuthedGuard } from './shared/guards/authed.guard';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {path:'',redirectTo:'login',pathMatch:'full'},
  {path:'login',component:LoginComponent,canActivate:[AuthedGuard]},
  {path:'forbidden',component:ForbiddenComponent},

  {path:'admin',component:HomeComponent,canActivate:[AuthGuard],children:[
    {path:'',loadChildren:'./pages/dashboard/dashboard.module#DashboardModule',canActivate:[RoleGuard],data: {roles: ["user", "admin",'manager','userClube']}},
    {path:'partners',loadChildren:'./pages/partiners/partiners.module#PartinersModule'},
    {path:'partnership',loadChildren:'./pages/partnership/partnership.module#PartnershipModule'},
    {path:'clubs',loadChildren:'./pages/clubs/clubs.module#ClubsModule'},
    {path:'members',loadChildren:'./pages/members/members.module#MembersModule'},
    {path:'news',loadChildren:'./pages/news/news.module#NewsModule'},
    {path:'quotes',loadChildren:'./pages/quotes/quotes.module#QuotesModule'},
    {path:'services',loadChildren:'./pages/servicos/servicos.module#ServicosModule'},
    {path:'products',loadChildren:'./pages/products/products.module#ProductsModule'},
    {path:'stock',loadChildren:'./pages/stock/stock.module#StockModule'},
    {path:'settings',loadChildren:'./pages/settings/settings.module#SettingsModule'},
    {path:'orders',loadChildren:'./pages/orders/orders.module#OrdersModule'},
    {path:'users',loadChildren:'./pages/users/users.module#UsersModule'},
  ]},
  {path:'**',component:NotFoundComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
