import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppApiInterface } from 'src/app/app.api';
import { User } from '../models/user.model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            
            const user:User=User.fromJsonToObject(JSON.parse(localStorage.getItem('user')))

            const requestUrl=req.url.split('/')
            const apiUrl=AppApiInterface.IMALI_API.split('/')
    
            
            if(user.token && apiUrl[2]==requestUrl[2]){
                const newRequest=req.clone({setHeaders:{'Authorization':`Bearer ${user.token.access_token}`}})
                return next.handle(newRequest);
            }


        return next.handle(req);
    }
}