import { AuthService } from 'src/app/shared/services/auth.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {

    constructor(public auth: AuthService, public router: Router, public aRoute: ActivatedRoute) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const userRole = this.auth.user.roles.length > 0? this.auth.user.roles[0].name:null;

        // verifica se os roles do utilizador existem ou não
        userRole?'':this.auth.logout();

        if(route.data.roles && route.data.roles.indexOf(userRole) == -1){
            //role not authorized
            this.router.navigate(["/forbidden"])

            return false
        }else{

            //authorized role
            return true;

        }


    }
}
