<header class="main-header">

  <!-- Logo -->
  <a routerLink="/admin" class="logo text-center">
    <!-- mini logo for sidebar mini 50xB0 pixels -->
    <span class="logo-mini" style="color:#FF0000"><b>KZ</b>L</span>
    <!-- logo for regular state and mobile devices -->
    <span  class="logo-lg">
      <img style="width:60px;height:50px;" src="assets/Khuzela.png"
        class="img-responsive" alt="Khuzela Logo">
      <!-- <b class="text-success bg-green" style="padding:7px;">IMALI</b>BUSINESS -->
    </span>
  </a>

  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top">
    <!-- Sidebar toggle button-->
    <a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>
    <!-- Navbar Right Menu -->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">

        <li class="dropdown messages-menu" *ngIf="auth.user.roles[0].name=='admin' || auth.user.roles[0].name=='user'">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i  class="fa fa-plus-circle text-danger"></i>
          </a>
          <ul class="dropdown-menu">

            <li class="header active"><b>Adicionar Novos dados</b></li>
            <li>
              <ul class="menu">

                <li><a routerLink="/admin/partners/new">Adicionar Parceiro</a></li>
                <li><a routerLink="/admin/services/new">Adicionar Serviço</a></li>
                <li><a routerLink="/admin/clubs/new">Adicionar Clube</a></li>
                <li><a routerLink="/admin/quotes/new">Adicionar Quotas</a></li>
                <li><a routerLink="/admin/members/new">Adicionar Sócios</a></li>
                <li><a routerLink="/admin/partnership/new">Adicionar Parceiros</a></li>
                <li><a routerLink="/admin/settings/category/new">Adicionar Categorias</a></li>
                <li><a routerLink="/admin/settings/subcategory/new">Adicionar Subcategorias</a></li>
                <li><a routerLink="/admin/settings/size/new">Adicionar Tamanhos</a></li>
                <li><a routerLink="/admin/settings/color/new">Adicionar Cores</a></li>
                <li><a routerLink="/admin/products/new">Adicionar Produtos</a></li>
                <li><a routerLink="/admin/stock/new">Adicionar Stock</a></li>
                <li><a routerLink="/admin/news/new">Adicionar Notícias</a></li>
                <li><a routerLink="/admin/users/new">Adicionar Utilizadores</a></li>

              </ul>
            </li>
          </ul>

        </li>

        <li class="dropdown user user-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <img [src]="auth.user.photo || 'https://imalimoz.herokuapp.com/users/avatar/Default-avatar.jpg'"
              class="user-image" alt="User Image">
            <span class="hidden-xs">{{ auth.user.name }}</span>
          </a>
          <ul class="dropdown-menu">
            <!-- User image -->
            <li class="user-header">
              <img [src]="auth.user.photo || 'https://imalimoz.herokuapp.com/users/avatar/Default-avatar.jpg'"
                class="img-circle" alt="User Image">

              <p>
                {{ auth.user.name }} - {{ auth.user.roles.shift().name }}
                <small>Registado em : {{ auth.user.created_at }}</small>
              </p>
            </li>


            <!-- Menu Footer-->
            <li class="user-footer">
              <div class="pull-left">
                <!-- <a href="#" class="btn btn-default btn-flat"><i class="fa fa-user"></i> Perfil</a> -->
              </div>
              <div class="pull-right">
                <button (click)="auth.logout()" class="btn btn-default btn-flat"><i class="fa fa-sign-out"></i>
                  Sair</button>
              </div>
            </li>
          </ul>
        </li>

      </ul>
    </div>

  </nav>
</header>


<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">
    <!-- Sidebar user panel -->
    <div class="user-panel" style="border-bottom: 2px solid #FF0000;">
      <div class="pull-left image">
        <img [src]="auth.user.photo || 'https://imalimoz.herokuapp.com/users/avatar/Default-avatar.jpg'"
          class="img-circle" alt="User Image">
      </div>
      <div class="pull-left info">
        <p>{{ auth.user.name }}</p>
        <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
      </div>
    </div>

    <ul class="sidebar-menu">

      <li class="treeview" routerLinkActive="active">
        <a  href="javascript:void(0)">
          <i class="fa fa-dashboard"></i> <span>Dashboard</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/dashboard"><i class="fa fa-dot-circle-o"></i>Indicadores</a></li>
        </ul>
      </li>
      <li class="treeview" routerLinkActive="active" *ngIf="isAdminOrUserKhuzela()">
        <a  routerLink="/admin/settings">
          <i class="fa fa-gear"></i> <span>Configurações</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/settings/category/list"><i class="fa fa-dot-circle-o"></i>Categorias</a></li>
          <li routerLinkActive="active"><a routerLink="/admin/settings/subcategory/list"><i class="fa fa-dot-circle-o"></i>Subcategorias</a></li>
          <li routerLinkActive="active"><a routerLink="/admin/settings/size/list"><i class="fa fa-dot-circle-o"></i>Tamanhos</a></li>
          <li routerLinkActive="active"><a routerLink="/admin/settings/color/list"><i class="fa fa-dot-circle-o"></i>Cores</a></li>
        </ul>
      </li>
      <li class="treeview" *ngIf="isAdminOrUserKhuzela()" routerLinkActive="active">
        <a  routerLink="/admin/partners">
          <i class="fa fa-briefcase"></i> <span>Parceiros</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/partners/list"><i class="fa fa-dot-circle-o"></i> Parceiros</a></li>
          <li routerLinkActive="active"><a routerLink="/admin/services/list"><i class="fa fa-dot-circle-o"></i> Serviços</a></li>
          <!-- <li routerLinkActive="active"><a routerLink="/admin/partinership"><i class="fa fa-dot-circle-o"></i> Parcerias</a></li> -->
        </ul>
      </li>

      <li class="treeview" routerLinkActive="active" *ngIf="isAdminOrUserKhuzela()">
        <a  routerLink="/admin/clubs">
          <i class="fa fa-soccer-ball-o"></i> <span>Clubes</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/clubs/list"><i class="fa fa-dot-circle-o"></i>Clubes</a></li>
          <li routerLinkActive="active"><a routerLink="/admin/quotes/list"><i class="fa fa-dot-circle-o"></i>Quotas</a></li>
        </ul>
      </li>

      <li class="treeview" routerLinkActive="active" *ngIf="auth.user.roles[0].name=='manager' || auth.user.roles[0].name=='userClube'">
        <a  routerLink="/admin/quotes">
          <i class="fa fa-money"></i> <span>Quotas</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/quotes/list"><i class="fa fa-dot-circle-o"></i>Quotas</a></li>
        </ul>
      </li>

      <li class="treeview" *ngIf="isManagerOrUserClub() ||  isAdminOrManager()" routerLinkActive="active">

        <a  routerLink="/admin/partnership" >
          <i class="fa fa-link"></i> <span>Parcerias</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/partnership/list"><i class="fa fa-dot-circle-o"></i>Parcerias</a></li>
        </ul>

      </li>


      <li class="treeview" routerLinkActive="active">

        <a  routerLink="/admin/members">
          <i class="fa fa-users"></i> <span>Sócios</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/members/list"><i class="fa fa-dot-circle-o"></i>Sócios</a></li>
        </ul>

      </li>




      <li class="treeview" routerLinkActive="active">

        <a  routerLink="/admin/products">
          <i class="fa fa-list"></i> <span>Produtos</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/products/list"><i class="fa fa-dot-circle-o"></i>Produtos</a></li>
          <li routerLinkActive="active"><a routerLink="/admin/stock/list"><i class="fa fa-dot-circle-o"></i>Stock</a></li>
        </ul>

      </li>

      <li class="treeview" routerLinkActive="active">
        <a  routerLink="/admin/news" >
          <i class="fa fa-newspaper-o"></i> <span>Notícias</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/news/list"><i class="fa fa-dot-circle-o"></i>Notícias</a></li>
        </ul>
      </li>

      <li class="treeview" *ngIf="auth.user.roles[0].name!=='userClube'" routerLinkActive="active">
        <a  routerLink="/admin/orders" >
          <i class="fa fa-shopping-cart"></i> <span>Pedidos</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/orders/list"><i class="fa fa-dot-circle-o"></i>Pedidos</a></li>
        </ul>

      </li>

      <li class="treeview" *ngIf="auth.user.roles[0].name!=='userClube'" routerLinkActive="active">
        <a  routerLink="/admin/users" >
          <i class="fa fa-user"></i> <span>Utilizadores</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li routerLinkActive="active"><a routerLink="/admin/users/list"><i class="fa fa-dot-circle-o"></i>Utilizadores</a></li>
        </ul>
      </li>


    </ul>
  </section>
  <!-- /.sidebar -->
</aside>
