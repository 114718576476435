import { ToastrManager } from 'ng6-toastr-notifications';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Subscription } from 'rxjs';
// import { REGEX } from './../../../app.validators';
import { REGEX } from 'src/app/app.validators';

@Component({
  selector: 'ng-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit,OnDestroy {

  public loginForm: FormGroup;
  public isButtonLoading: boolean = false;
  public isThereUrlParams:boolean=false;
  public loginSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private aroute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrManager,
  ) { this.loginSubscription=new Subscription();}

  ngOnDestroy(): void {
    if(!this.loginSubscription) return;

    this.loginSubscription.unsubscribe();
  }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: this.formBuilder.control('', [Validators.required, Validators.pattern(REGEX.email)]),
      password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)])
    })
  }

  onLogin(cridentials: {email: string, password: string}) {

    this.isButtonLoading = true

    this.loginSubscription.add(this.auth.login({email:cridentials.email,password:cridentials.password})

      .subscribe((isLogin: boolean)=>{

          if (isLogin) {

            this.isThereUrlParams=!!this.aroute.parent.snapshot.queryParams['url'];

            this.isThereUrlParams
            ?
            this.router.navigate([this.aroute.parent.snapshot.queryParams['url']])
            :
            this.router.navigate(['/admin'], { queryParams: { boas_vindas: 'bemvindo' } })

            this.toastr.successToastr('Login efectuado com sucesso!', 'Mensagem',{position:'top-center'})

          }

          this.isButtonLoading = false
          this.loginForm.reset()
      },
      () => {
        this.isButtonLoading = false
        this.loginForm.reset()
      }))
  }


}
