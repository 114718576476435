import { ToastrManager } from 'ng6-toastr-notifications';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError,flatMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AppApiInterface } from 'src/app/app.api';
import { Token, User } from '../models/user.model';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    
    constructor(public injector:Injector,public auth:AuthService,private toastr:ToastrManager){}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
        
        return next.handle(req).pipe(catchError((errorResponse:HttpErrorResponse)=>{
            
            const error=(typeof errorResponse.error !== 'object')? JSON.parse(errorResponse.error || '{}') : errorResponse.error

            if(errorResponse.status==401 && error.message=="Unauthenticated."){

                let user:User=User.fromJsonToObject(JSON.parse(localStorage.getItem('user')))

                const http=this.injector.get(HttpClient);

                let postData = {
                    grant_type: 'refresh_token',
                    refresh_token: user.token.refresh_token,
                    client_secret: '8NLKsY2Q8sADCx7hb2zHgRmYQQ23t5Yiu3SpOwHV',
                    client_id: 4,
                    scope: ''
                }

                return http.post<{
                    token_type:string,
                    expires_in:string,
                    access_token:string,
                    refresh_token:string
                }>(`${AppApiInterface.IMALI_API_AUTH}`,postData)
                .pipe(flatMap(authData=>{
                    
                    
                    user.token=new Token(
                        authData.token_type,
                        new Date(new Date().getTime()+parseInt(authData.expires_in)*1000),
                        authData.access_token,
                        authData.refresh_token
                    )

                    localStorage.setItem('user',JSON.stringify(user))
                        
                    this.toastr.successToastr('A sua sessão foi iniciada automaticamente com sucesso!', 'Alerta',{position:'top-center'})

                    const cloneRequest=req.clone({setHeaders:{'Authorization':`Bearer ${authData.access_token}`}})

                    return next.handle(cloneRequest)
                }))

              
            }

            return throwError(error)
        }))
    }
}