<app-bread-crumb [items]="[{text:'Erro 404'}]" [sectionTitle]="'Erro 404'"></app-bread-crumb>

  <!-- Main content -->
  <section class="content">
    <div class="error-page">
      <h2 class="headline text-yellow"> 404</h2>

      <div class="error-content">
        <h3><i class="fa fa-warning text-yellow"></i> Oops! Page not found.</h3>

        <p>
          Não achamos a pagina que estas a procura.
          Para voltar ao Dashboard <a routerLink="/admin">Click Aqui!</a> 
        </p>

      </div>
      <!-- /.error-content -->
    </div>
    <!-- /.error-page -->
  </section>
  <!-- /.content -->