<app-bread-crumb [items]="[{text:'Erro 403'}]" [sectionTitle]="'Erro 403'"></app-bread-crumb>

  <!-- Main content -->
  <section class="content">
    <div class="error-page">
      <h2 class="headline text-red"> 403</h2>

      <div class="error-content">
        <h3><i class="fa fa-warning text-yellow"></i> Oops! Página Proibida.</h3>

        <p>
          Não tens permissão para aceder a esta página.
          Para voltar ao Dashboard <a routerLink="/admin">Click Aqui!</a> 
        </p>

      </div>
      <!-- /.error-content -->
    </div>
    <!-- /.error-page -->
  </section>
  <!-- /.content -->