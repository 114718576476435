import { ActionTableBarComponent } from './components/actions-table-bar/actions-table-bar.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { BoxDashboardComponent } from './components/box-dashboard/box-dashboard.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ToastrModule } from 'ng6-toastr-notifications';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common'; 
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

// editor
import { AngularEditorModule } from '@kolkov/angular-editor';


// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// services
import { RefreshTokenInterceptor } from './interceptors/refresh-token-interceptor';
import { ErrorsInterceptor } from './interceptors/error-interceptor';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { NotificationService } from './services/notifications.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
// components
import { ButtonComponent } from './components/button/button.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { InputComponent } from './components/input/input.component';
import { TableComponent } from './components/table/table.component';
import { BoxLoadingComponent } from './components/box-loading/box-loading.component';
import { BoxContentComponent } from './components/box-content/box-content.component';
import { MessagesComponent } from './components/messages/messages.component';
import { RoleGuard } from './guards/role.guard';

import {  RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { BoxTableSpinnerComponent } from './components/box-table-spinner/box-table-spinner.component';
import { BoxComponent } from './components/box/box.component';
import { ChangeColorDirective } from './directives/app.directive';

@NgModule({
    
    declarations:[
        InputComponent,
        BreadCrumbComponent, 
        ButtonComponent,
        MessagesComponent,
        TableComponent,
        ChangeColorDirective,
        BoxDashboardComponent,
        ActionTableBarComponent,
        DatePickerComponent,
        BoxLoadingComponent,
        BoxTableSpinnerComponent,
        BoxComponent,
        BoxContentComponent,
    ],

    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot(),
        // for HttpClient use:
        LoadingBarHttpClientModule,

        RxReactiveFormsModule,

        // for Router use:
        LoadingBarRouterModule,

        // for Core use:
        LoadingBarModule,
        // editor
        AngularEditorModule,
        // image cropper
        ImageCropperModule,
        // multiselectdropdown
        // NgMultiSelectDropDownModule.forRoot()

    ],

    exports: [

        // module
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        ToastrModule,
        //  // for HttpClient use:
         LoadingBarHttpClientModule, 

         RxReactiveFormsModule,

         // for Router use:
         LoadingBarRouterModule,
 
         // for Core use:
         LoadingBarModule,

        //  Editor
         AngularEditorModule,

         // image cropper
        ImageCropperModule,

         // multiselectdropdown
        //  NgMultiSelectDropDownModule,

        // directives
        ChangeColorDirective,

        // components
        InputComponent,
        ActionTableBarComponent,
        BreadCrumbComponent,
        ButtonComponent,
        MessagesComponent,
        TableComponent,
        BoxDashboardComponent,
        BoxLoadingComponent,
        BoxContentComponent,
        BoxTableSpinnerComponent,
        BoxComponent,
        DatePickerComponent
    ],
    
})

export class SharedModule{

    static forRoot():ModuleWithProviders{
        return {
            ngModule:SharedModule,
            providers: [
                AuthService,
                NotificationService,
                AuthGuard,
                RoleGuard,
                {provide:HTTP_INTERCEPTORS,useClass:TokenInterceptor,multi:true},
                {provide:HTTP_INTERCEPTORS,useClass:RefreshTokenInterceptor,multi:true},
                {provide:HTTP_INTERCEPTORS,useClass:ErrorsInterceptor,multi:true},
                {provide:LocationStrategy,useClass:HashLocationStrategy}
            ]
        }
    }

}
