<section class="content-header">

      <h1 style="color:#f35309; font-weight: 400;">
        {{ sectionTitle }}
        <small>Pré-visualização</small>
      </h1>
   
      <ol class="breadcrumb">

        <li>
          <a style="font-size:16px;" [routerLink]="defaultLink"><i class="fa fa-dashboard"></i>dashboard</a>
        </li>

        <li *ngFor="let item of items" class="breadcrumb-item" [class.active]="isTheLastItem(item)">

          <span style="font-size:16px;" *ngIf="isTheLastItem(item)">
            {{ item.text }}
          </span>

          <a style="font-size:16px;" *ngIf="!isTheLastItem(item)" [routerLink]="item.link">
            {{item.text}}
          </a>

        </li>

      </ol>
    

</section>
