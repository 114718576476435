import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public currentYear:number;

  constructor(public auth: AuthService){
    this.currentYear=new Date().getFullYear();
    auth.user.isLoggedIn()
  }
}