import { Component, OnInit } from '@angular/core';

// models
import { Message } from '../../models/message.model';

// services
import { NotificationService } from '../../services/notifications.service';

@Component({
  selector: 'ng-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})

export class MessagesComponent implements OnInit {

  public message:Message;

  constructor(public notificationServ:NotificationService){
    this.message = new Message('','',false);
  }

  ngOnInit() {
    
    this.notificationServ.getMessages
    .subscribe((message: Message)=>{
      
      this.message=message

    })

  }

}
