import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'ng-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor(public auth:AuthService) {}

    // permissões
  isAdmin():boolean{
      return this.auth.user.roles[0].name==='admin';
  }

  isManager():boolean{
      return this.auth.user.roles[0].name==='manager';
  }

  isUserClub():boolean{
      return this.auth.user.roles[0].name==='userClube';
  }

  isUserKhuzela():boolean{
      return this.auth.user.roles[0].name==='user';
  }

  isAdminOrUserKhuzela():boolean{
      return this.isAdmin() || this.isUserKhuzela();
  }

  isManagerOrUserClub():boolean{
      return this.isManager() || this.isUserClub();
  }
  
  isAdminOrManager():boolean{
      return this.isManager() || this.isAdmin();
  }

  isAll():boolean{
    return this.isAdmin() || this.isUserKhuzela() || this.isManager() || this.isUserClub();
  }
}
