import { ToastrManager } from 'ng6-toastr-notifications';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

    constructor(
        private toastr: ToastrManager,
    ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {

                let errorMessage:string= error.error.message;

                if(error.error.error=='invalid_grant'){
                    errorMessage='Email ou Senha inválidos!';
                }else if(error.status==0){
                    errorMessage='Sem internet!';
                }else if(error.status==401){
                    errorMessage='Utilizador não autenticado!';
                }else if(error.status==403){
                    errorMessage='Utilizador não autorizado!';
                }else if(error.status==500){
                    errorMessage='Ocorreu um erro no servidor!'.concat(` \nMensagem:-> (${error.error.message}) \nLinha: -> ${error.error.line}`);
                }

                this.toastr.errorToastr(errorMessage, 'Alerta',{position:'top-center'})

                return next.handle(req);
            }));
    }
}